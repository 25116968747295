import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import bioreactor from "../../../Assets/Images/webp/photobioreactor.webp";
import platform from "../../../Assets/Images/webp/reduceCo2.webp";

import "../../../Assets/Styles/panels.css";

const products = [
  {
    title: "Reduce your emissions",
    image: platform,
    description:
      "Reduce your emissions by purchasing verified carbon certificates and monitorize your footprint directly from OPHSET platfrom.",
    industries: [
      "Renewable Energy",
      "Green Transportation",
      "Sustainable Agriculture",
    ],
    buttonText: "Start offseting",
    route: "/platform",
  },
  {
    title: "Prevent CO2 resulting from your operations",
    image: bioreactor,
    description:
      "Prevent the CO2 resulting from your operations to ever reach the atmosphere, through our on-site carbon capture technologies.",
    industries: ["Energy Efficiency", "Waste Reduction", "Circular Economy"],
    buttonText: "Explore Carbon Capture",
    route: "/photobioreactor",
  },
];

function ProductPanel(props) {
  const navigate = useNavigate();

  return (
    <Card
      className='panel'
      style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {" "}
      <Card.Img class='card-img-top' variant='top' src={props.image} />
      <Card.Body style={{ flexGrow: 1 }}>
        {" "}
        <Card.Title>{props.title}</Card.Title>
        <Card.Text>{props.description}</Card.Text>
        <Card.Text style={{ fontWeight: "bold", marginTop: "1rem" }}>
          Key industries:
        </Card.Text>
      </Card.Body>
      <ul>
        {props.industries.map((industry, index) => (
          <li key={index}>{industry}</li>
        ))}
      </ul>
      <Card.Footer>
        <Link to={props.route}>
          <Button
            className='panelButton'
            variant='primary'
            style={{
              backgroundColor: "#007D5C",
              borderColor: "#007D5C",
              transition: "background-color 0.5s ease",
              ":hover": {
                backgroundColor: "#59A591",
                borderColor: "#59A591",
              },
            }}>
            {props.buttonText}
          </Button>
        </Link>
      </Card.Footer>
    </Card>
  );
}

function Product() {
  return (
    <Container>
      <Row>
        <h2 className='text-center my-4'>
          There are 2 ways to decarbonize your operations through Ophset.
        </h2>
        {products.map((product, index) => (
          <Col md={6} key={index}>
            <ProductPanel
              title={product.title}
              image={product.image}
              description={product.description}
              subtitle={product.subtitle}
              industries={product.industries}
              buttonText={product.buttonText}
              route={product.route}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Product;
