import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
const PricingImg = require("../../Assets/Images/pricing.png");
const EmissionAuditingImg = require("../../Assets/Images/emission-auditing.png");
const CuratedPlansImg = require("../../Assets/Images/curated-plans.png");
const RadicalTransparencyImg = require("../../Assets/Images/radical-transparency.png");

function KeyFeatures() {
  return (
    <Container className='my-5'>
      <Row className='my-5'>
        <Col md={6} className='mb-4 mt-5'>
          <p>
            <span class='landing-numbers green-text-color'>01.</span>{" "}
            <sup class='landing-text px-2'>Pricing</sup>
          </p>
          <p>
            The price of a carbon certificate is correlated with the cost
            required to capture and sequester the CO2 from the atmosphere by
            that specific method.
          </p>
        </Col>
        <Col md={6} className='mb-4 d-flex'>
          <img src={PricingImg} alt='Pricing' className='w-100 h-100 mx-auto' />
        </Col>
      </Row>

      <Row className='my-5'>
      <Col md={6} className='mb-4 mt-3 order-md-last'>
          <p>
            <span class='landing-numbers green-text-color'>02.</span>{" "}
            <sup class='landing-text px-2'>Emissions Auditing</sup>
          </p>
          <p>
            Through OPHSET, CO2 emitters will be able to request an emissions
            analysis from specialized third-party auditing firms.
          </p>
        </Col>
        <Col md={6} className='mb-4 p-0 order-md-first'>
          <img
            src={EmissionAuditingImg}
            alt='Emission Auditing'
            className='landing-img rounded '
          />
        </Col>
        
      </Row>

      <Row className='my-5'>
        <Col md={6} className='mb-4 mt-4'>
          <p>
            <span class='landing-numbers green-text-color'>03.</span>{" "}
            <sup class='landing-text px-2'>Curated Plans</sup>
          </p>
          <p>
            Based on a company's monthly emissions and priorities, the platform
            creates a portfolio of companies and projects that capture the
            required amount of CO2, through a subscription. This ensures the
            attainment of sustainability goals and simplifies the process of
            purchasing offsets.
          </p>
        </Col>
        <Col md={6} className='mb-4 d-flex '>
          <img
            src={CuratedPlansImg}
            alt='Curated Plans'
            className='w-100 mx-auto rounded'
          />
        </Col>
      </Row>

      <Row className='my-5'>
      <Col md={6} className='mb-4 mt-2 order-md-last'>
          <p>
            <span class='landing-numbers green-text-color'>04.</span>{" "}
            <sup class='landing-text px-2'>Radical Transparency</sup>
          </p>
          <p>
            All information regarding operations and project implementation is
            periodically verified by us and displayed on each CO2 seller's page.
          </p>
        </Col>
        <Col md={6} className='mb-4 order-md-first'>
          <img
            src={RadicalTransparencyImg}
            alt='Radical Transparency'
            className='w-100 rounded'
          />
        </Col>
       
      </Row>
    </Container>
  );
}

export default KeyFeatures;
