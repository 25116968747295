import React from "react";

function Footer() {
  return (
    <footer className='bg-dark text-light py-3 mt-4'>
      <div className='container'>
        <p className='text-center mb-0'>
          &copy; {new Date().getFullYear()} OPHSET
        </p>
      </div>
    </footer>
  );
}

export default Footer;
