import React from "react";
import { useRef } from "react";

import Header from "../components/blueprints/Header";
import Footer from "../components/blueprints/Footer";
import Section from "../components/blueprints/Section";

import Hero from "../components/landingPage/Hero";

import Product from "../components/landingPage/product/Product";
import KeyFeatures from "../components/landingPage/KeyFeatures";

import ContactForm from "../components/landingPage/ContactForm";

function LandingPage() {
  const formRef = useRef(null);

  const menuContent = [
    {
      nav: "Home",
      title: "Home",
      id: "home-section",
      href: "/",

      content: <Hero formRef={formRef} />,
    },
    {
      nav: "Our products",
      title: "Our products",
      id: "product-section",
      href: "/product",
      content: <Product />,
    },
    {
      nav: "Why OPHSET",
      title: "KEY FEATURES",
      id: "features-section",
      href: "/features",
      content: <KeyFeatures />,
    },
    {
      nav: "Get in touch",
      title: "Get in Touch",
      id: "contact-section",
      href: "/contact",

      content: <ContactForm ref={formRef} />,
    },
  ];

  return (
    <>
      <Header menu={menuContent} />

      <main>
        {menuContent.map((item) => {
          return (
            <Section
              title={
                item.title === "Home" || item.title === "Get in Touch"
                  ? null
                  : item.title
              }
              content={item.content}
              id={item.id}
            />
          );
        })}
      </main>
      <Footer />
    </>
  );
}

export default LandingPage;
