/**
* This is an auto generated code. This code should not be modified since the file can be overwriten 
* if new genezio commands are executed.
*/
   
  import { Remote } from "./remote.js"
  
  export class Contact {
      static remote = new Remote("http://127.0.0.1:8083/Contact")
  
      static async addContact(contactData) {
          return Contact.remote.call("Contact.addContact", contactData)  
      }
  
      
  }
  
  export { Remote };
  