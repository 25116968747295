import React from "react";

import LandingPage from "./pages/LandingPage";
import SignUpPage from "./pages/SignUpPage";
import AdminPage from "./pages/AdminPage";
import LoginPage from "./pages/LoginPage";
import PhotobioreactorPage from "./pages/PhotbioreactorPage";
import PlatformPage from "./pages/PlatformPage";

import PrivateRoute from "./components/PrivateRoute";
import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import "typeface-montserrat";

function App() {
  const [login, setLogin] = useState(() => {});
  return (
    <>
      <Routes>
        <Route path='' element={<LandingPage />}></Route>
        <Route path='/login' element={<LoginPage login={login} />} />
        <Route path='/signup' element={<SignUpPage />}></Route>
        <Route
          path='/photobioreactor'
          element={<PhotobioreactorPage />}></Route>
        <Route path='/platform' element={<PlatformPage />}></Route>

        <Route
          path='/admin'
          element={
            <PrivateRoute login={login}>
              <AdminPage />
            </PrivateRoute>
          }></Route>
      </Routes>
    </>
  );
}

export default App;
