import { useState, forwardRef } from "react";
import { Form, Button } from "react-bootstrap";
import { Contact } from "../../sdk/contact.sdk.js";
import SuccessPopUp from "./SuccesPopUp.js";

function ContactForm(props, ref) {
  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [personalName, setPersonalName] = useState("");

  const [role, setRole] = useState("emitter");
  const [error, setError] = useState("");

  // Use this to popup a success message
  const [formCompleted, setFormCompleted] = useState(false);
  // Use this to popup a fail message - user already exists
  const [userExists, setUserExists] = useState(false);

  const handleOpenModal = () => {
    setFormCompleted(true);
  };

  const handleCloseModal = () => {
    setFormCompleted(false);
  };

  async function handleSubmit(event) {
    event.preventDefault();

    if (!companyName || !companyEmail || !personalName || !role) {
      setError("All fields are mandatory!");
      return;
    }
    setError("");

    const res = await Contact.addContact({
      companyName,
      companyEmail,
      personalName,
      role,
    });
    setError("res", res);
    if (!res.success) {
      setError(res.msg);
      setUserExists(res.success);
      return;
    } else {
      setFormCompleted(true);
    }
  }

  return (
    <Form
      ref={ref}
      onSubmit={handleSubmit}
      className='container rounded-4 py-4 col-md-6 '
      style={{ backgroundColor: "#007d5c", color: "white", fontWeight: 500 }}>
      <h2 className='text-center mb-5'>Get involved!</h2>

      <span className='text-warning font-weight-bold'>{error}</span>
      {formCompleted && userExists && (
        <SuccessPopUp
          onClose={handleCloseModal}
          headerMsg='Thanks for making a change!'
          msg='But you are already registered.'
          err={false}
        />
      )}
      <Form.Group controlId='companyName' className='mt-2'>
        <Form.Label className='text-center d-block mx-auto'>
          Company Name
        </Form.Label>
        <Form.Control
          type='text'
          className='w-75 py-2 mx-auto'
          value={companyName}
          onChange={(event) => setCompanyName(event.target.value)}
        />
      </Form.Group>

      <Form.Group controlId='companyEmail' className='mt-2'>
        <Form.Label className='text-center d-block mx-auto'>
          Company Email
        </Form.Label>
        <Form.Control
          type='email'
          className='w-75 py-2 mx-auto'
          value={companyEmail}
          onChange={(event) => setCompanyEmail(event.target.value)}
        />
      </Form.Group>

      <Form.Group controlId='personalName' className='mt-2'>
        <Form.Label className='text-center d-block mx-auto'>
          Your Name
        </Form.Label>
        <Form.Control
          type='text'
          className='w-75 py-2 mx-auto'
          value={personalName}
          onChange={(event) => setPersonalName(event.target.value)}
        />
      </Form.Group>

      <Form.Group controlId='role' className='mt-4 w-75  d-block mx-auto'>
        <Form.Check
          type='radio'
          label='My company emits CO2'
          name='role'
          value='emitter'
          checked={role === "emitter"}
          onChange={(event) => setRole(event.target.value)}
        />
        <Form.Check
          type='radio'
          label='My company captures CO2'
          name='role'
          value='capturer'
          checked={role === "capturer"}
          onChange={(event) => setRole(event.target.value)}
        />
      </Form.Group>

      <Button
        variant='dark'
        type='submit'
        className='w-75 mt-4 py-3 mx-auto d-block '
        onClick={handleOpenModal}>
        CLICK HERE TO CHANGE THE WORLD
      </Button>
      {formCompleted && !userExists && (
        <SuccessPopUp
          onClose={handleCloseModal}
          headerMsg='Thanks for making a change!'
          msg='Form submitted succesfully.'
          err={false}
        />
      )}
    </Form>
  );
}

export default forwardRef(ContactForm);
