import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { User } from "../sdk/user.sdk.js";
import { useNavigate } from "react-router-dom";

export default (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();
    if (!email || !password) {
      setError("All fields are mandatory");
      return;
    }

    setError("");

    const res = await User.login(email, password);

    if (!res.success) {
      setError(res.msg);
      return;
    } else {
      localStorage.setItem("apiToken", res.token);
      localStorage.setItem("user", JSON.stringify(res.user));

      if (res.user.userRole === "admin") {
        navigate("/admin");
      } else {
        navigate("/dashboard");
      }
    }
  }

  return (
    <Container className='mt-5'>
      <Row className='mt-5'>
        <Col sm='12' md={{ span: 6, offset: 3 }}>
          <Card className='p-4 mt-5'>
            <div className='auth-wrapper'>
              <div className='auth-inner'>
                <Form>
                  <h3>Sign In</h3>
                  <span className='text-danger'>{error}</span>
                  <Form.Group controlId='formBasicEmail'>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='Enter email'
                      autoComplete='email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId='formBasicPassword'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type='password'
                      placeholder='Password'
                      autoComplete='current-password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                  <div className='text-left'>
                    <Button
                      variant='primary'
                      type='submit'
                      onClick={(e) => handleSubmit(e)}>
                      Submit
                    </Button>
                  </div>
                  <div className='mt-2'>
                    <span>
                      Don't have an account? <a href='/register'>Register</a>
                    </span>
                  </div>
                </Form>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
