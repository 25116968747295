import React from "react";
import { Col, Container, Row, Image, ListGroup, Button } from "react-bootstrap";
import Header from "../components/blueprints/Header";
import Footer from "../components/blueprints/Footer";

const PhotobioreactorPage = () => {
  const photobioreactorData = [
    {
      id: "mini",
      title: "Small scale photobioreactor",
      productName: "Ophset Cube",
      image: require("../Assets/Images/webp/mini.webp"),
      description:
        "The Mini Photobioreactor is perfect for small-scale production, experimentation and research. It has a compact design and is easy to use.",
      volume: "1-10 L",
      powerConsumption: "30-100 W",
      efficiency: "30-50%",
      cost: "$3,000 - $7,000",
      features: [
        "Compact design",
        "Easy to use",
        "Low power consumption",
        "Suitable for small-scale production and research",
      ],
    },
    {
      id: "medium",
      title: "Medium scale photobioreactor",
      productName: "Ophset Cylinders",
      image: require("../Assets/Images/webp/mediu.webp"),
      description:
        "The Medium Photobioreactor is a versatile and reliable system that is ideal for both research and commercial applications. It can be customized to fit your specific needs.",
      volume: "10-100 L",
      powerConsumption: "100-500 W",
      efficiency: "50-70%",
      cost: "$10,000 - $20,000",
      features: [
        "Versatile and reliable",
        "Ideal for research and commercial applications",
        "Customizable to fit specific needs",
        "Higher volume capacity compared to small scale photobioreactor",
      ],
    },
    {
      id: "large",
      title: "Industrial scale Photobioreactor",
      productName: "Ophset Industrial",
      image: require("../Assets/Images/webp/mare.webp"),
      description:
        "The Large Photobioreactor is designed for high-volume production and is capable of producing large quantities of biomass. It is suitable for both research and commercial use.",
      volume: "100-1000 L",
      powerConsumption: "500-2000 W",
      efficiency: "70-90%",
      cost: "$50,000 - $100,000",
      features: [
        "Designed for high-volume production",
        "Capable of producing large quantities of biomass",
        "Suitable for research and commercial applications",
        "Higher power consumption compared to smaller photobioreactors",
      ],
    },
  ];

  const menuData = [
    {
      nav: "Home",
      title: "Home",
    },
    ...photobioreactorData.map((item) => {
      return {
        id: item.id,
        title: item.title,
      };
    }),
  ];

  const renderPhotobioreactor = (data) => {
    return data.map((item) => {
      return (
        <Col md={4} key={item.id} style={{ marginBottom: "30px" }}>
          <div
            className='h-100 d-flex flex-column'
            style={{
              backgroundColor: "#fff",
              borderRadius: "15px",
            }}>
            <div style={{ flex: "1 0 auto" }}>
              <div className='text-center'>
                <h2 style={{ color: "#007D5C", fontWeight: 600 }}>
                  {item.productName}
                </h2>
                <Image
                  src={item.image}
                  alt={item.title}
                  fluid
                  style={{ height: "350px", objectFit: "contain" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  padding: "20px",
                }}>
                <p style={{ marginBottom: "20px" }}>{item.description}</p>
                <ListGroup>
                  <ListGroup.Item>
                    <strong>Volume:</strong> {item.volume}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Power Consumption:</strong> {item.powerConsumption}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Efficiency:</strong> {item.efficiency}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Cost:</strong> {item.cost}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Features:</strong>
                    <ul className='mb-0'>
                      {item.features.map((feature) => (
                        <li key={feature}>{feature}</li>
                      ))}
                    </ul>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </div>
            <Button
              variant='primary'
              style={{
                backgroundColor: "#007D5C",
                borderColor: "#007D5C",
                borderRadius: "10px",
                padding: "10px 20px",
                ":hover": {
                  backgroundColor: "#59A591",
                  borderColor: "#59A591",
                },
              }}>
              Preorder Now
            </Button>
          </div>
        </Col>
      );
    });
  };

  return (
    <>
      <Header menu={{}} />
      <Container style={{ marginTop: "150px" }}>
        <Row style={{ display: "flex", flexWrap: "wrap" }}>
          {renderPhotobioreactor(photobioreactorData)}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default PhotobioreactorPage;
