import React from "react";

function Section({ title, content, id }) {
  return (
    <section className='mt-4' id={id}>
      <div className='container'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-md-12 '>
            <h2
              className='section-title mb-3 text-center'
              style={{ color: "#007d5c" }}>
              {title}
            </h2>
            <div className='section-content mb-0'>{content}</div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section;
