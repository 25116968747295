import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, scroller } from "react-scroll";

const logo = require("../../Assets/Images/logo.png");

function Header({ menu }) {
  return (
    <Navbar bg='light' expand='lg' fixed='top' className='shadow rounded'>
      <Navbar.Brand href='/'>
        <img
          src={logo}
          height='80'
          width='80'
          className='d-inline-block align-top'
          alt='Your Logo'
          style={{ marginLeft: "1.5rem" }}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />
      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav style={{ marginLeft: "auto" }}>
          {menu.length > 0 ? (
            menu.map((item, index) => (
              <Nav.Link
                href={`#${item.id}`}
                key={item.id}
                className={`shadow-sm ml-4 ${
                  index !== menu.length - 1 ? "mr-3" : ""
                } ${item.active ? "active rounded-lg" : "rounded"}`}>
                {item.title}
              </Nav.Link>
            ))
          ) : (
            <Nav.Link href='/' className='shadow-sm ml-4 rounded-lg'>
              Home
            </Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
      <style type='text/css'>
        {`
          .nav-link.active {
            background-color: #007D5C;
            color: #fff !important;
            
          }
          .nav-link:hover {
            background-color: #59A591;
            color: #fff;
          }
          .nav-link {
            margin-right: 10px;
          }
        `}
      </style>
    </Navbar>
  );
}

export default Header;
