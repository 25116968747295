import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/");
  };

  return (
    <button
      style={{
        position: "sticky",
        bottom: "1rem",
        left: "1rem",
        backgroundColor: "#007D5C",
        color: "white",
        padding: "0.5rem 1rem",
        borderRadius: "1rem",
        border: "none",
        outline: "none",
        cursor: "pointer",
        zIndex: "1",
      }}
      onClick={handleBackClick}
      className='back-button'>
      Back
    </button>
  );
};

const PlatformPage = () => {
  return (
    <div style={{ position: "relative", width: "100%", height: "1000px" }}>
      <iframe
        style={{ width: "100%", height: "100%", backgroundColor: "white" }}
        src='https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fysxw5mkP8T6nt0iIgB2JzI%2FOPHSET%3Ftype%3Ddesign%26node-id%3D1-5935%26scaling%3Dmin-zoom%26page-id%3D0%253A1%26starting-point-node-id%3D1%253A10578%26show-proto-sidebar%3D1&background=transparent&hide-ui=1'
        allowFullScreen></iframe>

      <BackButton />
    </div>
  );
};

export default PlatformPage;
