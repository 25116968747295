import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Paper from "@mui/material/Paper";
import { Select, MenuItem, InputLabel } from "@material-ui/core";
import ButtonGroup from "@mui/material/ButtonGroup";

/* BACKEND */
import { User } from "../sdk/user.sdk.js";
import { Navigate } from "react-router-dom";

const SignUp = () => {
  const theme = createTheme();
  const navigate = useNavigate();

  const [clientType, setClientType] = useState("emitter");
  const [companyType, setCompanyType] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [companyEmail, setCopmanyEmail] = useState("");
  const [password, setPassword] = useState("");
  const [companySize, setCompanySize] = useState("");

  const [error, setError] = useState("");

  const options =
    clientType === `capturer`
      ? ["Capture Project", "Offset Consulting Firm", "Capture Company"]
      : [
          "Government Entity",
          "Indiviudal",
          "Eductional Institute",
          "NGO",
          "Non-Government Entity: Corporation",
        ];

  const Copyright = (props) => {
    return (
      <Typography
        variant='body2'
        color='text.secondary'
        align='center'
        {...props}>
        {"Copyright © "}
        <Link color='inherit' href='https://mui.com/'>
          Ophset
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  };

  // Must create a more clear and understandable way to handle input data
  // Whatever the names - I should connect it with the API.
  async function handleSubmit(event) {
    event.preventDefault();
    const userData = {
      name,
      email: companyEmail,
      password,
      clientType,
      country: [country],
    };
    const companyData = {
      companyName,
      companyType,
      companySize,
    };

    // Check if all was completed
    const userValues = Object.values(userData);
    const companyValues = Object.values(companyData);
    for (const value in userValues) {
      if (!value) {
        setError("All fields are mandatory");
        console.log(error);
        return;
      }
    }

    for (const value in companyValues) {
      if (!value) {
        setError("All fields are mandatory");
        console.log(error);
        return;
      }
    }
    setError();

    console.log(userData);
    console.log(companyData);

    const res = await User.register(userData, companyData);

    setError("res", res);
    if (!res.succes) {
      setError(res.msg);
      console.log(error);
      navigate("/login");
      return;
    }

    setClientType("");
    setCompanyName("");
    setCompanyType("");
    setPassword("");
    setCountry("");
    setCompanySize("");
    setCopmanyEmail("");
    setName("");
    navigate("/login");
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Container component='main' maxWidth='sm' sx={{ mb: 4 }}>
        <Box
          display='flex'
          justifyContent='center'
          flexDirection='column'
          alignItems='center'>
          <Avatar sx={{ m: 1, bgcolor: "success.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sign up
          </Typography>
        </Box>
        <Paper
          variant='outlined'
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          elevation={3}>
          <Grid>
            <Container component='main' maxWidth='xs'>
              <Box
                component='form'
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}>
                <Grid container spacing={2} justifyContent='center'>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      color='success'
                      autoComplete='given-name'
                      name=''
                      required
                      fullWidth
                      id='company-name'
                      label='Company Name'
                      autoFocus
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      color='success'
                      required
                      fullWidth
                      id='size'
                      label='Number of employees'
                      name=''
                      autoComplete='family-name'
                      type='number'
                      value={companySize}
                      onChange={(e) => setCompanySize(e.target.value)}
                    />
                  </Grid>
                  <Grid item fullWidth>
                    <FormControl>
                      <InputLabel id='type-label'>My company:</InputLabel>
                      <ButtonGroup fullWidth labelId='type-label'>
                        <Button
                          item
                          style={{
                            maxWidth: "150px",
                            minWidth: "150px",
                            minHeight: "30px",
                          }}
                          onClick={(e) => setClientType(e.target.id)}
                          color='success'
                          id='emitter'
                          variant={
                            clientType === "emitter" ? "contained" : "outlined"
                          }>
                          Emits CO2
                        </Button>
                        <Button
                          style={{
                            maxWidth: "150px",
                            minWidth: "150px",
                            minHeight: "30px",
                          }}
                          onClick={(e) => setClientType(e.target.id)}
                          color='success'
                          id='capturer'
                          variant={
                            clientType === "capturer" ? "contained" : "outlined"
                          }>
                          Captures CO2
                        </Button>
                      </ButtonGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth item xs={12} sm={12}>
                      <Grid item rowSpacing={1} fullWidth>
                        <InputLabel shrink={true} id='my-select-label'>
                          Select company type
                        </InputLabel>
                        <Select
                          fullWidth
                          autoFocus
                          labelId='my-select-label'
                          id='companyType'
                          defaultValue='select your type'
                          value={companyType}
                          onChange={(e) => setCompanyType(e.target.value)}>
                          {options.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      color='success'
                      required
                      fullWidth
                      name='country'
                      label='Country of Registration'
                      type='text'
                      id='country'
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      color='success'
                      required
                      fullWidth
                      id='name'
                      label='Person of contact'
                      name='name'
                      autoComplete='name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      color='success'
                      required
                      fullWidth
                      id='email'
                      label='Email Address'
                      name='email'
                      autoComplete='email'
                      value={companyEmail}
                      onChange={(e) => setCopmanyEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      color='success'
                      required
                      fullWidth
                      name='password'
                      label='Password'
                      type='password'
                      id='password'
                      autoComplete='new-password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox color='success' value='allowExtraEmails' />
                      }
                      label='I want to receive inspiration, marketing promotions and updates via email.'
                    />
                  </Grid>
                </Grid>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                  color='success'>
                  Sign Up
                </Button>
                <Grid container justifyContent='flex-end'>
                  <Grid item>
                    <Link href='#' variant='body2'>
                      Already have an account? Sign in
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Grid>
        </Paper>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
};

export default SignUp;
