import React from "react";
import "../../Assets/Styles/Landing.css";

const backgroundCounterImage = require("../../Assets/Images/webp/backgroundCounterIndustry.webp");

function CO2Counter() {
  return (
    <div
      className='co2-container'
      style={{ backgroundImage: `url(${backgroundCounterImage})` }}>
      <div className='co2-iframe-container'>
        <iframe
          title='Tons of CO2 emitted into the atmosphere'
          src='https://www.theworldcounts.com/embeds/counters/23?background_color=transparent&color=white&font_family=%22Helvetica+Neue%22%2C+Arial%2C+sans-serif&font_size=40'
          style={{ border: "none", width: "100%", height: "100%" }}></iframe>
      </div>
    </div>
  );
}
export default CO2Counter;
