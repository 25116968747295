import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

function SuccessPopUp(props) {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    props.onClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header
        closeButton
        style={{ backgroundColor: "#007d5c", color: "white", fontWeight: 500 }}>
        <Modal.Title>{props.headerMsg}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ backgroundColor: "#007d5c", color: "white", fontWeight: 500 }}>
        {props.msg}
      </Modal.Body>
      <Modal.Footer
        style={{ backgroundColor: "#007d5c", color: "white", fontWeight: 500 }}>
        <Button variant='light' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SuccessPopUp;
