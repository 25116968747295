import { Container, Row, Col, Image } from "react-bootstrap";
import "../../Assets/Styles/Landing.css";
import CO2Counter from "./CO2Counter";

const heroImg = require("../../Assets/Images/webp/hero.webp");

function Hero({ formRef }) {
  const handleClick = () => {
    formRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Container className='' style={{ marginTop: "70px" }}>
        <Row>
          <CO2Counter />
        </Row>
        <Row>
          <Col md={6}>
            <h1 className='landing-h1 green-text-color'>
              JOIN US IN BUILDING THE GLOBAL CO2 ECONOMY
            </h1>
            <h3
              className='landing-h3 text-black '
              style={{ fontWeight: 500, marginBottom: "25px" }}>
              The <span className='green-text-color'>OPHSET</span> platform
              connects companies that emit carbon dioxide with those that
              capture it permanently.
            </h3>
            <h3 className='landing-h3 text-black' style={{ fontWeight: 500 }}>
              Our mission is to help companies completely erase their carbon
              footprint by facilitating the transaction of CO2 offsets based on
              captured and sequestered carbon dioxide.
            </h3>
            <button
              type='button'
              class='btn btn-dark fs-5 fw-bold  mx-auto py-2 my-3 w-100 '
              onClick={handleClick}>
              BECOME A PARTNER
            </button>
          </Col>
          <Col md={6}>
            <Image src={heroImg} alt='Hero Image' fluid rounded />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Hero;
